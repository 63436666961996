import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                How do you develop an Ethereum smart contract?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">To develop an Ethereum smart contract, you should be well-skilled in Solidity programming and be aware of how to write codes using Remix and Truffle consoles. However, this is not an easy task as it involves technical challenges and potential risks. By getting assistance from a professional Ethereum smart contract development company like Coinsclone, you can develop an Ethereum smart contract without worrying about the challenges and risks.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How much does it cost to develop an Ethereum smart contract?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The average cost to develop an Ethereum smart contract ranges between $5,000 to $7,000. However, this is not a fixed term and the actual cost will vary depending on the roles of the smart contract, the complexities involved, and more factors. If you would like to get an exact quote, feel free to contact our business team for better clarification.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                What programming language is used in the Ethereum smart contract development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Solidity is the main programming language widely used to create smart contracts on the Ethereum blockchain. This is the language compatible with Ethereum Virtual Machine across diverse industries.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How long does it take to develop an Ethereum smart contract?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The approximate time to develop an Ethereum smart contract with eminent features and solid functionality would take seven to ten days including the testing. However, depending upon the complexities of the smart contract programming this time duration will vary. Get your requirements discussed with our professionals to get an actual time to create a smart contract on Ethereum.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection
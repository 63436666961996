import React from "react"



class IndustriesEvolv extends React.Component {

  render() {
    return (

      <section className="usr-admin-tab benifor ethsmart pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Industries Evolving</span> With Ethereum Smart Contract Development
            </h2>
            <p className="text-center">
            We believe in quality-driven development approaches to bring multi-functional and custom smart contracts for different industries. Explore the wide array of industries our Ethereum smart contracts ruling are,
            </p>
            <div className="row">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <h3>Finance and Banking Services</h3>
                      <p className="pharagraph">
                      Prioritizing speed and automation, our Ethereum smart contracts are identical to banking services. They are effective in handling loan and insurance issuance, credential and data management, and many more.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Supply Chain and Logistics</h3>
                      <p className="pharagraph">
                      Our customized Ethereum smart contracts simplify logistics movement tracking. Also, businesses can record the supply chain data securely in blockchain with the help of our smart contracts.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Decentralized Applications</h3>
                      <p className="pharagraph">
                      Our Ethereum smart contracts power many decentralized applications for diverse use cases like gaming, Marketplaces, and more. Also, our smart contracts ensure top privacy and security. 
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Identity Management</h3>
                      <p className="pharagraph">
                      Ethereum smart contracts maintain true ownership without relying on any third party. Identity management includes KYC verification, providing access like face recognition, and more.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Gaming and Digital Collectibles</h3>
                      <p className="pharagraph">
                      Smart contracts facilitate the operation of non-fungible tokens in gaming and marketplace platforms. Leverage the true ownership, trading, and transferring of NFTs with custom smart contract development.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Decentralized Autonomous Organizations (DAOs)</h3>
                      <p className="pharagraph">
                      Our Ethereum smart contracts are compatible to facilitate DAO operations. They regulate governance rights, maintain member profiles, and regulate the organization’s operation in a controlled manner.
                      </p>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default IndustriesEvolv
